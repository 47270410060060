module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"davehall-blog","accessToken":"MC5YaHpBcnhVQUFDSUFMT25W.77-977-9Fe-_ve-_ve-_ve-_ve-_vV7vv73vv73vv71ULx_vv73vv73vv71TOO-_vRPvv73vv71IB--_vUjvv71077-9EQ","path":"/preview","previews":false,"pages":[{"type":"Blog_post","match":"/article/:uid","path":"/article","component":"/opt/build/repo/src/templates/article.js"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/images/favicon-32x32.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
